import axios from '@/libs/axios'

export function reqGetLetterOfAssignments(payload) {
  return axios.get('api/admin/letter-of-assignments', { params: payload })
}
export function reqGetLetterOfAssignment(id) {
  return axios.get(`api/admin/letter-of-assignments/${id}`)
}
export function reqCreateLetterOfAssignment(payload) {
  return axios.post('api/admin/letter-of-assignments', payload)
}
export function reqUpdateLetterOfAssignment(id, payload) {
  return axios.post(`api/admin/letter-of-assignments/${id}?_method=PATCH`, payload)
}
export function reqDeleteLetterOfAssignment(id) {
  return axios.delete(`api/admin/letter-of-assignments/${id}`)
}
export function reqUploadSppdLetterOfAssignment(id, payload) {
  return axios.post(`api/admin/letter-of-assignments/${id}/upload-sppd`, payload)
}