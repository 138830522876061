import axios from '@/libs/axios'

export function reqGetStaffs(payload) {
  return axios.get('api/admin/staffs', { params: payload })
}
export function reqGetStaff(id) {
  return axios.get(`api/admin/staffs/${id}`)
}
export function reqCreateStaff(payload) {
  return axios.post('api/admin/staffs', payload)
}
export function reqUpdateStaff(id, payload) {
  return axios.post(`api/admin/staffs/${id}?_method=PATCH`, payload)
}
export function reqDeleteStaff(id) {
  return axios.delete(`api/admin/staffs/${id}`)
}
