<template>
  <div>
    <!-- Task Card Header -->
    <b-card
      class="card mb-1"
      title="Tambah Surat Penugasan"
    >
      <form @submit.prevent="submitForm()">
        <div class="mt-2">
          <b-row>
            <!-- Nama Lengkap -->
            <b-col
              lg="6"
              class="mb-1"
            >
              <label class="d-block">Nama Agenda</label>
              <b-form-input
                v-model="formData.name"
                placeholder="Masukkan Nama Agenda"
                :state="!formData.name && formErrors.name ? false : undefined"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                {{ formErrors.name ? formErrors.name[0] : "" }}
              </b-form-invalid-feedback>
            </b-col>

            <b-col
              lg="6"
              class="mb-1"
            >
              <label class="d-block">Nomor Agenda</label>
              <b-form-input
                v-model="formData.number"
                :state="formErrors.number ? false : undefined"
                placeholder="Masukkan Nomor Agenda"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                {{ formErrors.number ? formErrors.number[0] : "" }}
              </b-form-invalid-feedback>
            </b-col>

            <b-col lg="6">
              <b-form-group
                label="Tanggal Agenda"
                :invalid-feedback="formErrors.date ? formErrors.date[0] : ''"
                :state="formErrors.date ? false : undefined"
              >
                <date-picker
                  v-model="formData.date"
                  type="datetime"
                  class="w-100"
                  value-type="format"
                  format="YYYY-MM-DD HH:mm"
                />
              </b-form-group>
            </b-col>

            <b-col lg="6">
              <b-form-group
                label="File Surat"
                :invalid-feedback="
                  formErrors.file ? formErrors.file[0] : ''
                "
                :state="formErrors.file ? false : undefined"
              >
                <b-form-file
                  v-model="formData.file"
                  :state="formErrors.file ? false : undefined"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
            </b-col>

            <b-col
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Visibilitas"
                :invalid-feedback="
                  formErrors.is_public ? formErrors.is_public[0] : ''
                "
                :state="formErrors.is_public ? false : undefined"
              >
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="formData.is_public"
                  :options="[{ text: 'Dilihat Semua Staff', value: 1 }, { text: 'Hanya Dilihat Oleh Peserta', value: 0 }]"
                  name="radio-options"
                />
              </b-form-group>
            </b-col>

            <!-- Alamat -->
            <b-col
              lg="6"
              class="mb-1"
            >
              <b-form-group
                label="Staff"
                :invalid-feedback="
                  formErrors.staffs ? formErrors.staffs[0] : ''
                "
                :state="formErrors.staffs ? false : undefined"
              >
                <v-select
                  v-model="formData.staffs"
                  multiple
                  :reduce="staff => staff.id"
                  :options="staffs"
                  label="name"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <div>
            <b-button
              variant="primary"
              class="float-right"
              type="submit"
            >
              <div class="d-flex align-items-center">
                <b-spinner
                  v-show="isLoading"
                  class="mr-1"
                  variant="light"
                  small
                />
                Simpan
              </div>
            </b-button>
          </div>
        </div>
      </form>
    </b-card>
  </div>
</template>

<script>
// Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BFormInvalidFeedback,
  BSpinner,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BFormFile,
  BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { avatarText } from '@core/utils/filter'
import { reqCreateLetterOfAssignment } from '@/api/admin/letterOfAssignment'
import { reqGetStaffs } from '@/api/admin/staff'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormDatepicker,
    BFormFile,
    BFormRadioGroup,
    DatePicker,
    vSelect,
    flatPickr,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      blobImage: '',
      staffs: [],
      formErrors: {},

      formData: {
        name: '',
        number: '',
        date: '',
        is_public: 1,
        file: undefined,
        staffs: [],
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.showPassword ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  async created() {
    await this.getStaffs()
  },
  methods: {
    avatarText,
    checkAuthorizeRole,
    getStaffs() {
      reqGetStaffs({ per_page: 999 }).then(response => {
        this.staffs = response.data.data.data
      })
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      const isValid = true

      return isValid
    },
    async submitForm() {
      this.isLoading = true
      if (this.validateForm()) {
        this.formErrors = {}
        const data = new FormData()
        data.append('name', this.formData.name)
        data.append('number', this.formData.number)
        data.append('date', this.formData.date)
        data.append('file', this.formData.file)
        data.append('is_public', this.formData.is_public)
        this.formData.staffs.forEach((element, index) => {
          data.append(`staffs[${index}]`, element)
        })

        await reqCreateLetterOfAssignment(data)
          .then(response => {
            // stop loading
            this.isLoading = false

            // show success toast
            this.showToast(
              'success',
              'Check',
              'Berhasil',
              response.data.message || 'Trainer Berhasil Ditambahkan',
            )

            this.$router.push({
              name: checkAuthorizeRole('letter-of-assignments.index'),
            })
          })
          .catch(error => {
            this.isLoading = false
            this.formErrors = error.response.data.errors

            // show error toast
            this.showToast(
              'danger',
              'AlertTriangleIcon',
              'Gagal',
              error.response.data.message || 'Trainer Gagal Ditambahkan',
            )
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.line-height-small {
  line-height: 1.5rem;
}

.small-gap-right {
  margin-right: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}
</style>
